import React from "react"
import {
  Footer,
  Container,
  Content,
  Icon,
  Columns,
  Column,
  Title,
} from "bloomer"
import { Link } from "gatsby"
import Logo from "../assets/nf-logo-white.svg"
import FooterImage from "../assets/internet-security.svg"

const MainFooter = () => {
  return (
    <Footer>
      <Container>
        <Columns>
          <Column isSize={3}>
            {/* Logo */}
            <Content isSize="small">
              <Logo className="logo" />
            </Content>
          </Column>
          {/* Company */}
          <Column isSize={2}>
            <Title isSize="5" hasTextColor="white">
              Company
            </Title>
            <ul className="footer-links">
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                 <a href="https://docs.netfence.io/blog" target="_blank" rel="noopener noreferrer">Blog</a>
              </li>
              <li>
                <Link to="/careers">Careers</Link>
              </li>
            </ul>
          </Column>

          {/* Product */}
          <Column isSize={2}>
            <Title isSize="5" hasTextColor="white">
              Product
            </Title>
            <ul className="footer-links">
              <li>
                <Link to="/product">Product</Link>
              </li>
              <li>
                <Link to="/pricing">Pricing</Link>
              </li>
              <li>
                <Link to="/service-status">Service Status</Link>
              </li>
            </ul>
          </Column>

          {/* Docs and Help */}
          <Column isSize={2}>
            <Title isSize="5" hasTextColor="white">
              Docs &amp; Help
            </Title>
            <ul className="footer-links">
              <li>
                <a href="https://docs.netfence.io/" target="_blank" rel="noopener noreferrer">Documentation</a>
              </li>
              <li>
                <Link to="/resources">Resources</Link>
              </li>
              <li>
                <Link to="/support">Support</Link>
              </li>
            </ul>
          </Column>

          {/* Graphic */}
          <Column isSize={3}>
            <FooterImage className="footer-image is-hidden-mobile" />
          </Column>
        </Columns>

        <div className="bottom-bar">
          <Columns>
            {/* Copyright */}
            <Column isSize={3} className="copyright">
              <Content isSize="small">
                <p>© {new Date().getFullYear()} Netfence Limited</p>
                <p>Company Registration Number: 12620097</p>
              </Content>
            </Column>
            <Column>
            </Column>
              <Column>
                <Content isSize="small">
                <div className="service-links">
                  <ul class="footer-links">
                    <li>
                      <Link to="/terms-of-service">Terms of Service</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/security">Security Policy</Link>
                    </li>
                  </ul>
                  </div>
                </Content>
              </Column>
           
            {/* Social */}
           
          </Columns>
        </div>
      </Container>
    </Footer>
  )
}

export default MainFooter
