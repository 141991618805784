import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Logo from "../assets/nf-logo-blue.svg"

import {
  Navbar,
  NavbarBrand,
  NavbarBurger,
  NavbarEnd,
  NavbarMenu,
  NavbarItem,
  Container,
  Icon,
  Control,
  Field,
  Button,
} from "bloomer"

export default class navbar extends Component {
  static propTypes = {
    siteTitle: PropTypes.string.isRequired,
  }
  state = {
    isOpen: false,
  }

  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    return (
      <Navbar className="is-spaced">
        <Container>
        <NavbarBrand>
          <Link to="/" className="navbar-item">
            <Logo className="logo" />
            {/* <img
                src={logo}
                style={{ marginRight: 5 }}
                alt={this.props.siteTitle}
              /> */}
            {/* Site title link */}
          </Link>
          <NavbarBurger
            isActive={this.state.isOpen}
            onClick={this.toggleCollapse}
          />
        </NavbarBrand>
        <NavbarMenu isActive={this.state.isOpen} onClick={this.toggleCollapse}>
          {/* Page nav links */}
          <NavbarEnd>
            <Link
              to="/solutions"
              className="navbar-link nav-item is-arrowless"
              activeClassName="is-active">
              Solutions
            </Link>
            <Link
              to="/services"
              activeClassName="is-active"
              className="navbar-link nav-item is-arrowless">
              Services
            </Link>
            <Link
              to="/about"
              activeClassName="is-active"
              className="navbar-link nav-item is-arrowless">
              About
            </Link>
            <Link
              to="/contact"
              activeClassName="is-active"
              className="navbar-link nav-item is-arrowless">
              Contact
            </Link>
            
            <div className="nav-divider" />
            
            <NavbarItem>
              <Field isGrouped>
              {/* Login Button */}
              <Control>
                <Button
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="is-sqare"
                  isSize="small">
                  <span>Login</span>
                </Button>
              </Control>
              {/* Trial Button */}
              <Control>
                <Button
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="is-sqare has-background-warning"
                  isSize="small">
                  <span>Free Trial</span>
                  {/* <Icon className="fab fa-github fa-sm" />*/}
                </Button>
              </Control>
              </Field>
            </NavbarItem>
          </NavbarEnd>
        </NavbarMenu>
        </Container>
      </Navbar>
    )
  }
}
